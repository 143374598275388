<template>
  <div id="member-subscription-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ YearName }} - {{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
        <!-- <v-icon icon size="25px" color="primary">
          mdi-account-multiple-plus
        </v-icon>-->
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form2" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Area</h6>
                </label>
                <v-autocomplete
                  @change="getZoneCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="AreaCodeOptionsLoading"
                  :items="AreaCodeOptions"
                  :rules="AreaCodeRules"
                  v-model="AreaCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Zone</h6>
                </label>
                <v-autocomplete
                  @change="getRegionCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Region
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="RegionCodeOptionsLoading"
                  :items="RegionCodeOptions"
                  :rules="RegionCodeRules"
                  v-model="RegionCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Lom
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Search</v-btn
                >
                <v-btn
                  @click.prevent="resetForm"
                  color="#0f9d58"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Reset</v-btn
                >
                <!-- <v-btn
                  v-if="tableData2.length > 0"
                  @click="tableOptions2.dataTreeStartExpanded = true"
                  color="#8a2be2"
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  <v-icon>mdi-minus</v-icon> Expand
                </v-btn>
                <v-btn
                  v-if="tableData2.length > 0"
                  @click="tableOptions2.dataTreeStartExpanded = false"
                  color="#00008b"
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  <v-icon>mdi-plus</v-icon> Collapse
                </v-btn>-->
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container class="py-0" v-if="tableData1 <= 0">
          <v-row wrap v-if="tableData2.length == 0">
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>{{ tableData1.length - 1 }} records found.</h4>
              <json-excel
                v-if="
                  tableData2.length > 0 &&
                  tableOptions2.JsonDownloadButtonFlag == true
                "
                :name="tableOptions2.ExcelFileName"
                :data="tableData2"
                :fields="tableOptions2.JsonExcelFields"
                worksheet="My Worksheet"
              >
                <v-btn
                  color="#008000"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Download</v-btn
                >
              </json-excel>
            </v-col>
          </v-row>
          <!-- <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <VueTabulator v-model="tableData2" :options="tableOptions2" />
            </v-col>
          </v-row>-->
          <v-row wrap v-if="tableData2.length > 0 && TableDisplayFlag1 == true">
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                :item-key="tableOptions2.ItemKey"
                :single-select="tableOptions2.SingleSelectFlag"
                v-model="selected"
                :headers="tableColumns2"
                :items="tableData2"
                :items-per-page="tableOptions2.ItemsPerPage"
                :group-by="tableOptions2.groupBy"
                :search="search"
                :footer-props="tableOptions2.FooterProps"
                :show-group-by="tableOptions2.ShowGroupBy"
                :show-expand="tableOptions2.ShowExpand"
                :hide-actions="tableOptions2.HideActions"
              >
                <template slot="body.append">
                  <tr class="pink--text">
                    <th class="title" colspan="3">Totals</th>
                    <th class="title">{{ sumField("SelectedCount1") }}</th>
                    <th class="title">{{ sumField("RemainingCount1") }}</th>
                    <th class="title">{{ sumField("SelectedCount2") }}</th>
                    <th class="title">{{ sumField("RemainingCount2") }}</th>
                    <th class="title">{{ sumField("SelectedCount3") }}</th>
                    <th class="title">{{ sumField("RemainingCount3") }}</th>
                    <th class="title">{{ sumField("SelectedCount4") }}</th>
                    <th class="title">{{ sumField("RemainingCount4") }}</th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row wrap v-if="tableData1.length > 0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  v-if="DownloadFlag"
                  color="info"
                  @click.prevent="
                    generateExcel(
                      tableData1,
                      ExcelFields,
                      ExcelFileName,
                      'My Worksheet'
                    )
                  "
                  class="mr-4"
                  >Download</v-btn
                >
              </v-col>
              <p></p>
            </v-row>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search1"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                :search="search1"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom v-if="item.SendFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="#7209b7"
                        @click="sendCertificate(item)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-email</v-icon
                      >
                    </template>
                    <span>Send Positive Growth Certificate </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DownloadFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <a :href="item.LomDocumentAssetPath" target="_blank"
                        ><v-icon
                          icon
                          size="25px"
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-download</v-icon
                        ></a
                      >
                    </template>
                    <span>Download Positive Growth Certificate </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      FloatingButtonFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      TableDisplayFlag1: false,

      zone: {},
      lom: {},

      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: "Item 1",
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,

      JciYearCode: "",

      AreaCodeRules: [(v) => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCodeRules: [],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      search1: "",
      selected1: "",
      tableColumns1: [],
      tableData1: [],
      tableOptions1: {},

      tableColumns2: [],
      tableData2: [],
      tableOptions2: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.pageData();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.AreaCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "friday_report",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getAreaCodeOptions();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    sumField(key) {
      // sum data in give key (property)
      return this.tableData2.reduce((a, b) => a + (b[key] || 0), 0);
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.getTableRecords();
    },
    resetForm() {
      this.search = "";
      this.tableData2 = [];
      this.selected = [];
      this.$refs.form2.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    previewData(tr) {
      console.log("previewData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var url = "/members/subscription/assign";
        var id = tr.LomPaymentId;
        console.log("id=" + id);
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one lom to preview";
        this.toast("error", message);
      }
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      this.AreaCodeOptionsLoading = true;
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/year-wise-lom/area-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
        YearCode: this.JciYearCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      var areaCode = this.AreaCode;
      this.LomCodeOptions = [];
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "" && areaCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 7,
          AreaCode: areaCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
      /* else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        if (areaCode == "") {
          message += "Area should not be empty. ";
        }
        this.toast("error", message);
      }
      */
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      var YearCode = this.JciYearCode;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode
      );
      if (YearCode != "" && AreaCode != "" && ZoneCode != "") {
        this.RegionCode = "";
        this.RegionCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "RegionCode";
        var selectbox1_destination = "RegionCodeOptions";
        var selectbox1_url = "api/year-wise-lom/region-options";
        var selectbox1_conditions_array = {
          UserInterface: 4,
          YearCode: YearCode,
          AreaCode: AreaCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
      /*
       else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (AreaCode == "") {
          message += "Area should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
      */
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      this.tableData1 = [];
      this.LomCode = "";
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode
      );
      if (YearCode != "" && ZoneCode != "" && RegionCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          RegionCode: RegionCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        if (RegionCode == "") {
          message += "Region should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? 0 : LomCode;
      console.log("LomCode=" + LomCode);

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-lom/membership-status";
        var upload = {
          UserInterface: 2,
          YearCode: this.JciYearCode,
          AreaCode: this.AreaCode,
          ZoneCode: this.ZoneCode,
          RegionCode: this.RegionCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.ExcelDownloadFlag = false;
        this.ExcelFileName = "";
        this.ExcelFileFlag = false;
        this.TableDisplayFlag1 = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.rows = [];
        this.ResultFlag = false;
        this.LoadingFlag = true;
        this.StartupLoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            var TableOptions = records.TableOptions;

            thisIns.tableColumns2 = records.TableHeader;
            thisIns.tableOptions2 = TableOptions;
            thisIns.tableData2 = records.TableData;

            thisIns.ExcelDownloadFlag = TableOptions.ExcelDownloadFlag;
            thisIns.ExcelFileName = TableOptions.ExcelFileName;
            // thisIns.TableDisplayFlag1 = TableOptions.TableDisplayFlag1;

            if (flag == 1) {
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill required fields.";
        this.toast("error", message);
      }
    },
    searchForm() {
      console.log("searchForm called");

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? 0 : LomCode;
      console.log("LomCode=" + LomCode);

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/reports/friday-report";
        var upload = {
          UserInterface: 1,
          Year: this.JciYearCode,
          Area: this.AreaCode,
          Zone: this.ZoneCode,
          Lom: this.LomCode,
          Region: this.RegionCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.tableData1 = [];
        this.LoadingFlag = true;
        this.SearchFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            var options = records.TableOptions;
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;

              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill required fields.";
        this.toast("error", message);
      }
    },
    generateReportExcel() {
      console.log("generateReportExcel is called");

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? 0 : LomCode;
      console.log("LomCode=" + LomCode);

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.ExcelFileName = "";
        this.ExcelLoadingFlag = true;
        this.ExcelFileFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url =
          server_url + "api/reports/friday-report/generate-excel-v2";
        var upload = {
          UserInterface: 1,
          Year: this.JciYearCode,
          Area: this.AreaCode,
          Zone: this.ZoneCode,
          Region: this.RegionCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()

            thisIns.ExcelLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ExcelFileName = records.ExcelFileName;
              thisIns.ExcelDownloadFlag = records.ExcelDownloadFlag;
              thisIns.ExcelFileFlag = true;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ExcelLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    sendCertificate(tr) {
      console.log("sendCertificate is called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });
      if (n1 > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/send-certificate";

        var upload = {
          UserInterface: 2,
          LoName: tr.LomId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        this.LoadingFlag = true;
        this.OverlayLoadingFlag = true;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;
            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.tableColumns1.push(
      { text: "Paid Date", value: "PaidDateTxt" },
      { text: "Year", value: "YearName" },
      {
        text: "Subscription Description",
        value: "ContributionTypeDescription",
      },
      { text: "Paid Count", value: "PaidCount" },
      { text: "Remaining Count", value: "RemainingCount" },
      { text: "Add members", value: "actions" }
    );
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#member-subscription-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>